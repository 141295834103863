import * as XLSX from "xlsx";

export const downloadCsv = () => {
  const data = [
    {
      "Date Generate (MM/DD/YYYY)": "",
      Name: "",
      Carrier: "",
      Member: "",
      "Program Type": "",
      "Customer Email": "",
      "Email Status": "",
      Attention: "",
      "Tracking Number": "",
      "Store Number": "",
      "Address Line 1": "",
      "Address Line 2": "",
      City: "",
      State: "",
      Zip: "",
    },
  ];

  const worksheet = XLSX.utils.json_to_sheet(data);
  const csv = XLSX.utils.sheet_to_csv(worksheet);

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = "Data Template.csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
